import React from "react";
import logo from "../images/Mask_Group_32.png";
import { FaRegBell } from "react-icons/fa";
function Navigation() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "15px",
        marginRight: "10px",
        alignItems: "center",
      }}
    >
      {/* <FaRegBell style={{ marginRight: 30 }} /> */}
      <div>
        <div style={{ display: "flex", alignContent: "center" }}>
          <img
            src={logo}
            alt="logo"
            style={{
              marginRight: 10,
              borderRadius: "50%",
              width: 50,
              height: 50,
            }}
          />
          <div>
            <p style={{ margin: 0, padding: 0, fontSize: 16 }}>Byron</p>
            <p style={{ margin: 0, padding: 0, fontSize: 10 }}>Admin</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navigation;
