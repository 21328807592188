import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import "../styles/style.css";
import Api from "../middleware";
// import AWS from "aws-sdk";
import { uploadFile } from "react-s3";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { saveAs } from "file-saver";
import axios from "axios";
toast.configure();

// let outputdisplay = "";

let downloadFile = "";

function NewTitrationmodal(props) {
  const [showModal, setShow] = useState(true);

  const handleClose = (params) => {
    props.data(params);
    props.onClose();
  };

  // const titname = (name) => {
  //   //
  //   props.name();
  //   console.log(name, "Tit NAme");
  // };
  // props.name();
  // console.log(name);
  // console.log(props && props.name, "Props Data");

  const S3_BUCKET = "solpub-list-data";
  const REGION = "us-east-1";
  const ACCESS_KEY = "AKIAXTPFNQTGZJPN6Z6Y";
  const SECRET_ACCESS_KEY = "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";

  const config = {
    bucketName: S3_BUCKET,
    region: REGION,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
  };

  // const [selectedMFile, setSelectedMFile] = useState(null);
  const [sourceData, setSourceData] = useState(null);
  const [matchData, setMatchData] = useState(null);

  const handleFileUpload = async (file) => {
    const result = await uploadFile(file, config)
      .then((data) => {
        // console.log(data && data.location);
        // console.log(data.location, "path of the file");
        return data.location;
      })
      .catch((err) => console.error(err));
    return result;
  };
  // react-hook form

  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm();

  // const url =
  //   "http://ec2-54-82-104-63.compute-1.amazonaws.com:8080/list-tool-runner/rest/titration/runStep?";

  const saveUrl =
    "http://ec2-3-86-231-21.compute-1.amazonaws.com:8080/list-tool-runner/rest/titration/save?";

  // const saveUrl =
  //   "https://listool.digitivy.com/list-tool-runner/rest/titration/save?";

  const [data, setData] = useState({
    // fileName: "",
    titrationName: "",
    sourceFile: "",
    matchFile: "",
    sourceCol: "",
    action: "",
    confidence: "",
    matchType: "",
  });
  // aws bucket
  // const myBucket = new AWS.S3({
  //   params: { Bucket: S3_BUCKET },
  //   region: REGION,
  // });
  // onSubmit api gets invoked

  // const onSubmit = async (data) => {
  //   const sfile = await handleFileUpload(sourceData);
  //   // console.log(sfile);
  //   const mfile = await handleFileUpload(matchData);
  //   // console.log(mfile);

  //   const requestObj = {
  //     method: "POST",
  //     url: "http://ec2-3-83-191-160.compute-1.amazonaws.com:8080/list-tool-runner/rest/titration/runStep?",

  //     params: {
  //       titrationName: "st",
  //       userName: "sk",
  //       client: "Microsoft",
  //       fileName: data.fileName,
  //       sourceFile: sfile,
  //       matchFile: mfile,
  //       sourceCol: data.sourceCol,
  //       matchCol: data.matchCol,
  //       matchType: data.matchType,
  //       matchZipWildCard: data.matchZipWildCard,
  //       action: data.action,
  //       confidence: data.confidence,
  //     },
  //   };

  //   const response = await Api(requestObj);
  //   downloadFile = response.response[0];
  //   console.log("download file url", downloadFile);
  //   // console.log(response);
  //   if (response.response[1] === "successfully connected") {
  //     notify();
  //   }
  //   if (
  //     response.response === "Internal Server Error" &&
  //     response.status === 500
  //   ) {
  //     notify();
  //   }
  // };
  // const notify = () => {
  //   toast.success(
  //     "successfully connected",
  //     { position: toast.POSITION.TOP_CENTER },
  //     { autoClose: 3000 }
  //   );
  // };
  // // const notifydanger = () => {
  // //   toast.danger(
  // //     "Internal Server Error",
  // //     { position: toast.POSITION.TOP_CENTER },
  // //     { autoClose: 3000 }
  // //   );
  // // };

  // const saveTitration = async (data) => {
  //   const saveUrl =
  //     "http://listtoollb-31117622.us-east-1.elb.amazonaws.com/list-tool-runner/rest/titration/save?";
  //   // "http://ec2-3-83-191-160.compute-1.amazonaws.com/list-tool-runner/rest/titration/save?";
  //   // "https://listool.digitivy.com/list-tool-runner/rest/titration/save?";

  //   const sfile = await handleFileUpload(sourceData);
  //   // console.log(sfile);
  //   const mfile = await handleFileUpload(matchData);
  //   // console.log(mfile);
  //   if (
  //     !data.stepName ||
  //     !sfile ||
  //     !mfile ||
  //     !data.sourceCol ||
  //     !data.matchCol ||
  //     !data.matchType ||
  //     !data.wildCardSearch ||
  //     !data.action ||
  //     !data.confidenceThreshold
  //   ) {
  //     console.log("none of them are null");
  //   } else {
  //     console.log("values are null");
  //   }
  //   const requestObj = {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //     },
  //     // url: "http://ec2-3-83-191-160.compute-1.amazonaws.com:8080/list-tool-runner/rest/titration/save?",
  //     // url: "https://listool.digitivy.com/list-tool-runner/rest/titration/save?",
  //     url: "http://listtoollb-31117622.us-east-1.elb.amazonaws.com:8080/list-tool-runner/rest/titration/save?",
  //     // url: "http://ec2-3-86-231-21.compute-1.amazonaws.com:8080/list-tool-runner/rest/titration/save?",

  //     params: {
  //       titrationName: props && props.name,
  //       stepName: data.stepName,
  //       sourceFile: sfile,
  //       matchFile: mfile,
  //       sourceCol: data.sourceCol,
  //       matchCol: data.matchCol,
  //       matchType: data.matchType,
  //       // matchZipWildCard: data.matchZipWildCard,
  //       wildCardSearch: data.wildCardSearch,
  //       action: data.action,
  //       confidenceThreshold: data.confidenceThreshold,
  //     },
  //   };
  //   const response = await Api(requestObj);
  //   console.log("response from save titration", response);
  //   handleClose(data);
  // };

  //   // () => handleClose(data);
  //   // const response = await Api(requestObj);
  //   // console.log(response);
  //   // let output = JSON.stringify(response);
  //   // let output1 = JSON.stringify(response);
  //   // history.push({
  //   //   pathname: "/runoutput",
  //   //   state: { detail: response },
  //   // });
  //

  //   // if (response.status === 200) {
  //   //   // history.push({
  //   //   //   pathname: "/addstep",
  //   //   //   state: { detail: response },
  //   //   // });
  //   //   notify();
  //   // }
  //   // let output = JSON.stringify(response);
  //   // let output1 = response;
  //   // console.log(response["steps"][0]["name"]);
  //   // console.log(output1["response"]["steps"][0]);
  //   // outputdisplay = output1["response"]["steps"][0];
  //   // console.log(output1.response, "output variable");
  //   // console.log(output1);
  // };

  const saveTitration = async (data) => {
    const sfile = await handleFileUpload(sourceData);
    const mfile = await handleFileUpload(matchData);

    // params.append("titrationName", props && props.name);
    // params.append("stepName", data.stepName);
    // params.append("sourceFile", sfile);
    // params.append("matchFile", mfile);
    // params.append("sourceCol", data.sourceCol);
    // params.append("matchCol", data.matchCol);
    // params.append("matchType", data.matchType);
    // params.append("wildCardSearch", data.wildCardSearch);
    // params.append("action", data.action);
    // params.append("confidenceThreshold", data.confidenceThreshold);
    const check = JSON.stringify({
      name: "Prithvi",
      address: "Hyderabad",
      titrationName: "Dts",
      stepName: "T6",
      sourceFile: "Neustar_Test_SourceFile.csv",
      matchFile: "NetSuiteSuppressionList.csv",
      sourceCol: "Email",
      matchCol: "Domain",
      matchType: "emailDomain",
      action: "remove",
      confidenceThreshold: "0.9",
      wildCardSearch: "",
    });
    const res = await axios.post(
      // "http://listtoollb-31117622.us-east-1.elb.amazonaws.com/list-tool-runner/rest/titration/test",
      // "http://ec2-3-86-231-21.compute-1.amazonaws.com:8080/list-tool-runner/rest/titration/save?",
      // "https://listool.digitivy.com/list-tool-runner/rest/titration/save",
      //"https://listool.digitivy.com/list-tool-runner/rest/titration/test",
      // "http://ec2-3-86-231-21.compute-1.amazonaws.com:8080/list-tool-runner/rest/titration/save?",
      // "http:/ec2-3-86-231-21.compute-1.amazonaws.com:8080/list-tool-runnet/rest/titration/save?",
      // "https://listtoollb-31117622.us-east-1.elb.amazonaws.com/list-tool-runner/titration/save?",
      // "https://listoolapi.digitivy.com/list-tool-runner/titration/save?",
      // "http://listtoollb-31117622.us-east-1.elb.amazonaws.com:8080/list-tool-runner/titration/save?",
      // "https://948icncdp3.execute-api.us-east-1.amazonaws.com/list-tool-runner/titration/save",
      // "https://listoolapi.digitivy.com/list-tool-runner/rest/titration/save?",
      // "https://listool.digitivy.com/list-tool-runner/titration/save?",
      // "https://listoolapi.digitivy.com/list-tool-runner/rest/titration/save?",
      // "https://listool.digitivy.com/list-tool-runner/rest/titration/save?",
      // "https://listoolapi.digitivy.com/list-tool-runner/rest/titration/save?",
      "https://listoolapi.digitivy.com/list-tool-runner/rest/titration/test",
      check,
      {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          "Content-Type": "application/json",
        },
      }
    );
    console.log(res.data, "Data");
    console.log(res.data.headers, "Content");
    console.log(check, "Data being sent into api call");
    handleClose(data);
  };

  // console.log(setData, "data storage");

  const onChangeFile = (e) => {
    // aws configuration.

    // react hook form.

    if (e.target.name === "sourceFile") {
      setValue("sourceFile", e.target.files[0].name);
      setSourceData(e.target.files[0]);
      // setSourceData(e.target.files && e.target.files[0].name);
    }
    if (e.target.name === "matchFile") {
      setValue("matchFile", e.target.files[0].name);
      setMatchData(e.target.files[0]);
    }

    e.target.value = null;
  };

  const saveFile = () => {
    saveAs(downloadFile);
    // props.OnClose();
  };

  // console.log(setValue, "defined data");

  if (data) {
    return (
      <>
        <Modal show={showModal} onHide={handleClose} centered size="md">
          <div>
            <Modal.Header closeButton style={{ border: "none" }}>
              <h4 style={{ fontSize: 22 }}>New Titration Step</h4>
            </Modal.Header>
            <Modal.Body>
              <form className="data">
                <div>
                  <label htmlFor="stepName">Name</label>
                  <input
                    type="text"
                    {...register("stepName", { required: true })}
                    autoComplete="off"
                  />
                  {errors.fileName?.type === "required" && (
                    <p style={{ color: "red", fontSize: 12 }}>
                      fileName is required
                    </p>
                  )}
                </div>
                <br />
                <div>
                  <label htmlFor="sourceFile">Source File</label>
                  <input
                    type="text"
                    {...register("sourceFile", { required: true })}
                    autoComplete="off"
                  />
                  {errors.sourceFile === "required" && (
                    <p style={{ color: "red", fontSize: 12 }}>
                      File need to be selected
                    </p>
                  )}
                  <label className="custom-file-upload">
                    <input
                      type="file"
                      name="sourceFile"
                      style={{ display: "none" }}
                      // {...register("sourceFile", { required: true })}
                      onChange={(e) => {
                        onChangeFile(e);
                      }}

                      // autoComplete="off"
                    />
                    {errors.sourceFile?.type === "csv" && (
                      <p style={{ color: "red", fontSize: 12 }}>
                        csv files are required
                      </p>
                    )}
                    Choose
                  </label>
                </div>
                <br />

                <div>
                  <label htmlFor="matchFile">Match File</label>
                  {/* <span>{data.matchFile}</span> */}
                  <input
                    type="text"
                    {...register("matchFile", { required: true })}
                    autoComplete="off"
                  />
                  <label className="custom-file-upload">
                    <input
                      type="file"
                      name="matchFile"
                      placeholder="Match File"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        onChangeFile(e);
                      }}
                      autoComplete="off"
                    />
                    Choose
                    {errors.matchFile?.type === "csv" && (
                      <p style={{ color: "red", fontSize: 12 }}>
                        csv files are required
                      </p>
                    )}
                  </label>
                </div>
                <br />

                <div>
                  <label htmlFor="sourceCol">Source Col</label>
                  <input
                    type="text"
                    {...register("sourceCol", { required: true })}
                    autoComplete="off"
                  />
                  {errors.sourceCol && (
                    <p style={{ color: "red", fontSize: 12 }}>
                      Source Col cannot be empty.
                    </p>
                  )}
                </div>
                <br />

                <div>
                  <label htmlFor="matchCol">Match Col</label>
                  <input
                    type="text"
                    {...register("matchCol", { required: true })}
                    autoComplete="off"
                  />
                  {errors.matchCol && (
                    <p style={{ color: "red", fontSize: 12 }}>
                      Match Col cannot be empty.
                    </p>
                  )}
                </div>
                <br />
                <div>
                  <label htmlFor="matchType">Match Type</label>
                  <select
                    name="matchType"
                    id="matchType"
                    {...register("matchType", { required: true })}
                    autoComplete="off"
                  >
                    <option value=""></option>
                    <option value="emailAddress">Email Address</option>
                    <option value="companyName">Company Name</option>
                    <option value="emailDomain">Email Domain</option>
                    <option value="name">Name</option>
                    <option value="zip">Zip</option>
                    <option value="state">State</option>
                    <option value="title">Title</option>
                    <option value="wildCardSearch">Wild Card</option>
                    <option value="phoneNumber">Phone Number</option>
                  </select>
                  {errors.matchType && (
                    <p style={{ color: "red", fontSize: 12 }}>
                      Match Type cannot be empty.
                    </p>
                  )}
                </div>
                <br />
                {/* <div>
                  <label htmlFor="matchZipWildCard">Wild Card</label>
                  <input
                    type="text"
                    {...register("matchZipWildCard", { required: true })}
                    autoComplete="off"
                  />
                </div> */}
                <br />
                <div>
                  <label htmlFor="action">Action</label>
                  <select
                    name="action"
                    id="action"
                    {...register("action", { required: true })}
                  >
                    <option value=""></option>
                    <option value="match">match</option>
                    <option value="remove">suppress</option>
                  </select>
                  {errors.sourceCol && (
                    <p style={{ color: "red", fontSize: 12 }}>
                      Action cannot be empty.
                    </p>
                  )}
                </div>
                <br />
                <div>
                  <label htmlFor="confidenceThreshold">Confidence</label>
                  <input
                    type="number"
                    step="0.1"
                    {...register("confidenceThreshold", { required: true })}
                    style={{ width: 103 }}
                    autoComplete="off"
                  />
                  {errors.sourceCol && (
                    <p style={{ color: "red", fontSize: 12 }}>
                      Confidence cannot be empty.
                    </p>
                  )}
                </div>
                <br />

                <br />
                <div
                  style={{
                    textAlign: "right",
                    color: "#1D417A",
                    paddingTop: 10,
                  }}
                >
                  <button
                    className="formbtn"
                    // type="submit"
                    onClick={handleSubmit(saveTitration)}
                    // onClick={titname}
                  >
                    Save
                  </button>
                </div>
              </form>
            </Modal.Body>
          </div>
        </Modal>
      </>
    );
  }
  return null;
}

export default NewTitrationmodal;
