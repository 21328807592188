import axios from "axios";

const API = async (reqObj) => {
  // console.log(reqObj, "reqObj");
  try {
    const response = await axios(reqObj);
    return {
      response: response.data,
      status: response.status,
    };
  } catch (error) {
    console.log(error);
    return {
      response: error?.response?.data || {},
      status: error?.response?.status || null,
    };
  }
};

export default API;
