import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/style.css";
import NewTitrationmodal from "./NewTitrationmodal";
import { Row, Col, Container, Modal } from "react-bootstrap";
import Api from "../middleware";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { saveAs } from "file-saver";
import { uploadFile } from "react-s3";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";

toast.configure();

let path = "";
// let step = [];
let stepsID = "";
let campaignID = "";
let titname = "";
let multiPath = "";

function Steps(props) {
  const location = useLocation();
  const [redirectpath, setredirectPath] = useState("");
  const [results, setResults] = useState([]);
  const [titdetails, settitDetails] = useState();
  const [text, setText] = useState();
  const [edit, setEdit] = useState(false);
  const [matchData, setMatchData] = useState(null);
  const [titrationName, setTitrationName] = useState("");
  const [name, setName] = useState("");
  const [multiStep, setMultiStep] = useState([]);

  const { register, setValue, handleSubmit } = useForm();

  const S3_BUCKET = "solpub-list-data";
  const REGION = "us-east-1";
  const ACCESS_KEY = "AKIAXTPFNQTGZJPN6Z6Y";
  const SECRET_ACCESS_KEY = "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";

  // useEffect(() => {
  //   console.log(
  //     location &&
  //       location.state &&
  //       location.state.detail &&
  //       location.state.detail.titrationName,
  //     "titration Name"
  //   );
  // }, [location]);

  useEffect(() => {
    // stepsID = location && location.state.stepID;
    // campaignID = location && location.state.campaignID;
    setResults((results) => [
      ...results,
      location &&
        location.state &&
        location.state.detail.response &&
        location.state.detail.response.steps &&
        location.state.detail.response.steps[0],
    ]);
    setTitrationName(
      location &&
        location.state &&
        location.state.detail.response &&
        location.state.detail.response.titrationName
    );
  }, [location]);

  console.log(stepsID);

  console.log(titrationName);

  // const tn = titrationName
  //   ? titrationName
  //   : location.state.detail.titrationName
  //   ? location.state.detail.titrationName
  //   : null;

  // console.log(tn, "testing titname");

  const closeComponent = () => {
    setredirectPath("/");
  };

  const handleSampleClose = () => {
    setShow(false);
  };
  const handleData = (data) => {
    setResults((results) => [...results, data]);
  };

  const [showModal, setShow] = useState(false);
  const handleShow = (props) => {
    setShow(true);
    setName(
      titrationName
        ? titrationName
        : location.state.detail.titrationName
        ? location.state.detail.titrationName
        : null
    );
  };

  const ColorChange = (data1) => {
    setText(data1);
    setMultiStep((multiStep) => [...multiStep, data1]);
    console.log(multiStep, "data in multistep");
  };

  // console.log(
  //   "titration name from load api",
  //   location.state.detail.titrationName
  // );
  console.log("multi step", multiStep);

  const webApp = (path) => {};

  // Single step submission api call

  // const submitStep = async (data1) => {
  //   const requestObj = {
  //     method: "POST",
  //     headers: { "Content-Type": "multipart/form-data" },
  //     // url: "http://ec2-3-83-191-160.compute-1.amazonaws.com:8080/list-tool-runner/rest/titration/runStep?",
  //     url: "https://listool.digitivy.com/list-tool-runner/rest/titration/runStep?",
  //     // url: "http://listtoollb-31117622.us-east-1.elb.amazonaws.com/list-tool-runner/rest/titration/runStep?",

  //     params: {
  //       stepID: location && location.state && location.state.stepID,
  //       campaignID: location && location.state && location.state.campaignID,
  //       titrationName: titrationName
  //         ? titrationName
  //         : location.state.detail.titrationName
  //         ? location.state.detail.titrationName
  //         : null,
  //       stepName: text && text.stepName,
  //       sourceFile: text && text.sourceFile,
  //       matchFile: text && text.matchFile,
  //       sourceCol: text && text.sourceCol,
  //       matchCol: text && text.matchCol,
  //       matchType: text && text.matchType,
  //       // matchZipWildCard: results[0] && results[0].matchZipWildCard,
  //       wildCardSearch: "",
  //       action: text && text.action,
  //       confidenceThreshold: text && text.confidenceThreshold,
  //     },
  //   };

  //   const response = await Api(requestObj);
  //   console.log(response);
  //   path = response.response[0];
  //   let message = response.response[1];
  //   webApp();

  //   if (response.status === 200) {
  //     notify();
  //   }
  // };

  // const notify = () => {
  //   toast.success(
  //     "successfully submitted step",
  //     { position: toast.POSITION.TOP_CENTER },
  //     { autoClose: 3000 }
  //   );
  // };

  const submitStep = async (data1) => {
    // const sfile = await handleFileUpload(sourceData);
    // const mfile = await handleFileUpload(matchData);
    const check = JSON.stringify({
      titrationName: "Dts",
      stepName: "T6",
      sourceFile: "Neustar_Test_SourceFile.csv",
      matchFile: "NetSuiteSuppressionList.csv",
      sourceCol: "Email",
      matchCol: "Domain",
      matchType: "emailDomain",
      action: "remove",
      confidenceThreshold: "0.9",
      wildCardSearch: "",
    });
    const res = await axios.post(
      // "http://listtoollb-31117622.us-east-1.elb.amazonaws.com/list-tool-runner/rest/titration/test",
      // "http://ec2-3-86-231-21.compute-1.amazonaws.com:8080/list-tool-runner/rest/titration/save?",
      // "https://listool.digitivy.com/list-tool-runner/rest/titration/save?",
      //"https://listool.digitivy.com/list-tool-runner/rest/titration/test",
      // "http://ec2-3-86-231-21.compute-1.amazonaws.com:8080/list-tool-runner/rest/titration/save?",
      "https://listool.digitivy.com/list-tool-runner/rest/titration/runStep",
      check,
      {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          "Content-Type": "application/json",
        },
      }
    );
    console.log(res.data, "Data");
    console.log(res.data.headers, "Content");
    console.log(check, "Data being sent into api call");
    // handleClose(data);
  };

  const onChangeFile = (e) => {
    // aws configuration.

    // react hook form.
    if (e.target.name === "matchFile") {
      setValue("matchFile", e.target.files[0].name);
      setMatchData(e.target.files[0]);
    }

    e.target.value = null;
  };

  // Edit step Dialogue box opens and displays the selected step details.
  const showEdit = () => {
    setEdit(true);
    setValue("stepName", text.stepName);
    setValue("sourceCol", text.sourceCol);
    setValue("matchCol", text.matchCol);
    setValue("matchFile", text.matchFile);
    setValue("sourceFile", text.sourceFile);
    setValue("matchType", text.matchType);
    setValue("action", text.action);
    setValue("confidenceThreshold", text.confidenceThreshold);
  };

  // Edit step Dialogue box closes
  const editClose = () => setEdit(false);

  const config = {
    bucketName: S3_BUCKET,
    region: REGION,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
  };

  const handleFileUpload = async (file) => {
    const result = await uploadFile(file, config)
      .then((data) => {
        return data.location;
      })
      .catch((err) => console.error(err));
    return result;
  };

  const onSave = async (data1) => {
    const mfile = await handleFileUpload(matchData);
    const requestObj = {
      method: "POST",
      // headers: { "Content-Type": "multipart/form-data" },
      // url: "http://ec2-3-83-191-160.compute-1.amazonaws.com:8080/list-tool-runner/rest/titration/save?",
      url: "https://listool.digitivy.com/list-tool-runner/rest/titration/save",
      // url: "http://listtoollb-31117622.us-east-1.elb.amazonaws.com:8080/list-tool-runner/rest/titration/save?",

      params: {
        titrationName: titrationName
          ? titrationName
          : location.state.detail.titrationName
          ? location.state.detail.titrationName
          : null,
        stepName: data1.stepName,
        matchFile: mfile,
        sourceFile: data1 && data1.sourceFile,
        sourceCol: data1.sourceCol,
        matchCol: data1.matchCol,
        matchType: data1.matchType,
        // matchZipWildCard: data.matchZipWildCard,
        wildCardSearch: data1.wildCardSearch,
        action: data1.action,
        confidenceThreshold: data1.confidenceThreshold,
      },
    };

    const response = await Api(requestObj);

    // console.log(data1);
    setText(data1);
    setEdit(false);
    setResults((results) => [...results, data1]);
    console.log("Data from edit function", text);
    console.log("response from new save", response);
  };

  const multiCall = async () => {
    //   console.log(multiStep, "inside api function");
    // const data = JSON.stringify([multiStep]);
    // const data = JSON.stringify({ name: "ad", address: "asa" });
    // const data = JSON.stringify({ name: "ad", address: "asa" });
    // console.log(data, "Data in the api");
    const emp = JSON.stringify({ name: "ad", address: "asa" });
    // const requestObj = {
    //   method: "POST",
    //   mode: "no-cors",
    //   headers: {
    //     "Content-Type": "application/json",
    //     "Access-Control-Allow-Origin": "*",
    //     "Access-Control-Expose-Headers": "Content-Length, X-JSON",
    //     // "Content-Length": "emp.length",
    //     "Access-Control-Allow-Methods":
    //       "GET, POST, PATCH, PUT, DELETE, OPTIONS",
    //     "Access-Control-Allow-Headers":
    //       "Content-Type, Authorization, Accept, Accept-Language, X-Authorization",
    //   },
    //   // url: "http://ec2-3-83-191-160.compute-1.amazonaws.com:8080/list-tool-runner/rest/titration/multiStep",
    //   // url: "http://ec2-3-83-191-160.compute-1.amazonaws.com:8080/list-tool-runner/rest/titration/test",
    //   url: "http://listool.digitivy.com/api",
    //   body: { emp },
    // };
    // const response = await Api(requestObj);
    // console.log(response, "async and await call");
    // axios({
    //   method: "POST",
    //   mode: "no-cors",
    //   headers: {
    //     "Content-Type": "application/json",
    //     "Access-Control-Allow-Origin": "*",
    //   },
    //   url: "http://ec2-3-83-191-160.compute-1.amazonaws.com:8080/list-tool-runner/rest/titration/test",
    //   crossDomain: true,
    // })
    //   .then((res) => {
    //     console.log(res, "resss");
    //     if (res.status === 200) {
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error, "errrrrrrrrrrrrrrrr");
    //   });
    // const options = {
    //   method: "post",
    //   mode: "no-cors",
    //   headers: new Headers({
    //     "Content-Type": "application/json",
    //     "Content-length": "",
    //   }),
    //   //
    // };
    // console.log(emp);
    // fetch("http://listool.digitivy.com/api", options, {
    //   method: "post",
    //   body: { emp },
    // })
    //   .then((response) => response.text())
    //   .then((result) => console.log(result, "fetch api call"))
    //   .catch((error) => console.log("error", error));

    // axios({
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //     // "Content-Type": "multipart/form-data",
    //   },
    //   url: "http://listtoollb-31117622.us-east-1.elb.amazonaws.com/list-tool-runner/rest/titration/test?",
    //   crossDomain: true,
    //   body: { emp },
    // })
    //   .then((res) => {
    //     if (res.status === 200) {
    //       // dispatch(registerSuccess(res.data));
    //       console.log(res, "resssss");
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error, "errrrrr");
    //     // dispatch(registerFailure(error.response));
    //   });
    const json = JSON.stringify({ name: "ad", address: "asa" });
    const res = await axios.post(
      // "http://listtoollb-31117622.us-east-1.elb.amazonaws.com/list-tool-runner/rest/titration/test",
      "https://listool.digitivy.com/list-tool-runner/rest/titration/test",
      json,
      {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          "Content-Type": "application/json",
        },
      }
    );

    // res.data.data; // '{"answer":42}'
    // res.data.headers["Content-Type"];
    console.log(res.data, "Data");
    console.log(res.data.headers, "Content");
    console.log(json, "Data being sent into api call");
  };

  const download = () => {
    if (path != null) {
      saveAs(path);
    } else {
      saveAs(multiPath);
    }
  };

  console.log(results, "data in results");

  return (
    <Row>
      <Col xs={2} md={2} style={{ paddingRight: 0, paddingLeft: 0 }}>
        <Sidebar />
      </Col>
      <Col
        xs={10}
        md={10}
        style={{ paddingLeft: 0, backgroundColor: "#F9FBFD" }}
      >
        <Navbar />
        <h3 style={{ paddingLeft: 30, color: "#1D417A" }}>Titrations</h3>
        <Container>
          <Col sm={8} md={8} lg={12}></Col>
          <Col sm={12} md={12} lg={12}>
            <div className="boxform">
              <div style={{ textAlign: "right", padding: 16 }}>
                <button className="btn1" onClick={handleShow}>
                  Add Step
                </button>
                <button className="btn1" onClick={showEdit}>
                  Edit Step
                </button>
                <button className="btn1">Remove Step</button>
              </div>
              <div>
                <p style={{ paddingLeft: 20, fontSize: 20 }}>Titration Steps</p>
              </div>
              {showModal && (
                <NewTitrationmodal
                  data={handleData}
                  onClose={handleSampleClose}
                  name={name}
                />
              )}
              <div>
                <Modal show={edit} onHide={editClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Edit Titration Step</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <form className="data">
                      <div>
                        <label htmlFor="stepName">Name</label>
                        <input
                          type="text"
                          {...register("stepName", { required: true })}
                          autoComplete="off"
                        />
                      </div>
                      <br />
                      <div>
                        <label htmlFor="matchFile">Match File</label>
                        <input
                          type="text"
                          {...register("matchFile", { required: true })}
                          autoComplete="off"
                        />
                        <label className="custom-file-upload">
                          <input
                            type="file"
                            name="matchFile"
                            placeholder="Match File"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              onChangeFile(e);
                            }}
                            autoComplete="off"
                          />
                          Choose
                        </label>
                      </div>
                      <div>
                        <label htmlFor="sourceCol">Source Col</label>
                        <input
                          type="text"
                          {...register("sourceCol", { required: true })}
                          autoComplete="off"
                        />
                      </div>
                      <div>
                        <label htmlFor="matchCol">Match Col</label>
                        <input
                          type="text"
                          {...register("matchCol", { required: true })}
                          autoComplete="off"
                        />
                      </div>
                      <div>
                        <label htmlFor="matchType">Match Type</label>
                        <select
                          name="matchType"
                          id="matchType"
                          {...register("matchType", { required: true })}
                          // autoComplete="off"
                        >
                          <option value=""></option>
                          <option value="emailAddress">Email Address</option>
                          <option value="companyName">Company Name</option>
                          <option value="emailDomain">Email Domain</option>
                          <option value="name">Name</option>
                          <option value="zip">Zip</option>
                          <option value="state">State</option>
                          <option value="title">Title</option>
                          <option value="wildCardSearch">Wild Card</option>
                          <option value="phoneNumber">Phone Number</option>
                        </select>
                      </div>
                      <div>
                        <label htmlFor="action">Action</label>
                        <select
                          name="action"
                          id="action"
                          {...register("action", { required: true })}
                        >
                          <option value=""></option>
                          <option value="match">match</option>
                          <option value="remove">suppress</option>
                        </select>
                      </div>
                      <div>
                        <label htmlFor="confidenceThreshold">Confidence</label>
                        <input
                          type="number"
                          step="0.1"
                          {...register("confidenceThreshold", {
                            required: true,
                          })}
                          // style={{ width: 103 }}
                          // autoComplete="off"
                        />
                      </div>
                      <div>
                        <button
                          className="formbtn"
                          onClick={handleSubmit(onSave)}
                        >
                          Save
                        </button>
                        <button className="formbtn" onClick={editClose}>
                          Cancel
                        </button>
                      </div>
                    </form>
                  </Modal.Body>
                </Modal>
              </div>
              <div className="box1">
                <div>
                  {/* {results &&
                    results.length > 0 &&
                    results.map((data1, index) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          borderBottom: "1px solid #ccc",
                          padding: "14px",
                        }}
                        key={index}
                      >
                        <div>{data1 && data1.fileName}</div>
                        <div>{data1 && data1.confidence}</div>
                        <div>{data1 && data1.sourceCol}</div>
                        <div>{data1 && data1.sourceFile}</div>
                        <div>{data1 && data1.matchFile}</div>
                        <div>{data1 && data1.matchType}</div>
                      </div>
                    ))} */}
                  {results && results.length > 0
                    ? results.map((data1, index) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            borderBottom: "1px solid #ccc",
                            padding: "14px",
                            backgroundColor: { text },
                            cursor: "pointer",
                          }}
                          onClick={() => ColorChange(data1)}
                          key={index}
                        >
                          <p>{data1 && data1.name}</p>
                          <p>{data1 && data1.sourceCol}</p>
                          <p>{data1 && data1.matchCol}</p>
                          <p>{data1 && data1.matchType}</p>
                          <p>{data1 && data1.stepName}</p>
                          <p>{data1 && data1.confidence}</p>
                          <p>{data1 && data1.matchMode}</p>
                        </div>
                      ))
                    : titdetails && titdetails.length > 0
                    ? titdetails.map((data1, index) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            borderBottom: "1px solid #ccc",
                            padding: "14px",
                          }}
                          key={index}
                        >
                          <div>{data1.sourceFile}</div>
                        </div>
                      ))
                    : null}
                </div>
              </div>
              <div style={{ textAlign: "right", padding: 18 }}>
                <button className="submitstep" onClick={submitStep}>
                  Submit Step
                </button>
                <button className="submitallsteps" onClick={multiCall}>
                  Submit All steps
                </button>
                {/* <button className="submitallsteps">Submit All Steps</button> */}
                <button
                  className="downloadfinalresults"
                  // onClick={() => {
                  //   saveAs(path);
                  // }}
                  onClick={download}
                >
                  Download Final Result
                </button>
              </div>
              {/* <div>
                <p style={{ textAlign: "left", paddingLeft: 20, fontSize: 20 }}>
                  Progress
                </p>
              </div>
              <div className="box1"></div> */}
              <div style={{ textAlign: "right", padding: 20 }}>
                {redirectpath && redirectpath !== "" ? (
                  <Redirect to={redirectpath} />
                ) : null}
                <button className="close" onClick={closeComponent}>
                  Close
                </button>
              </div>
            </div>
          </Col>
        </Container>
      </Col>
    </Row>
  );
}

export default Steps;
