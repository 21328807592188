import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/style.css";
import { FaTimes } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { Redirect, useHistory, withRouter } from "react-router-dom";

function NewTitrations(props) {
  let history = useHistory();
  const close = () => {
    props.success(false);
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  // const onSubmit = (data) => console.log(data);

  const [steps, setSteps] = useState(false);
  const [redirectpath, setredirectPath] = useState("");
  // const [sfile, setSfile] = useState("");
  // const closeComponent = () => {
  //   // handleSubmit(onSubmit);
  //   setredirectPath("/addstep");
  //   // setSteps(true);
  // };

  const onSubmit = async (data) => {
    // handleSubmit(data);
    // console.log(data, "new function is working");
    history.push({
      pathname: "/addstep",
      state: { detail: data.titrationName },
    });
    setSteps(true);
  };

  return (
    <div>
      {redirectpath && redirectpath !== "" ? (
        <Redirect to={redirectpath} />
      ) : null}
      <div className="ntbox">
        {/* <form className="data" onSubmit={handleSubmit(closeComponent)}> */}
        <form className="data">
          <div className="topbar">
            <h4>New Titration</h4>
            <FaTimes onClick={close} />
          </div>
          <br />
          <div>
            <div>
              <label htmlFor="titrationName">Name</label>
              <input {...register("titrationName", { required: true })} />
              {errors.Name?.type === "required" && (
                <p style={{ color: "red", fontSize: 12 }}>Name is required</p>
              )}
            </div>
            <br />
            {/* <div>
              <label htmlFor="userName">Username</label>
              <input {...register("userName", { required: true })} />
              {errors.Username?.type === "required" && (
                <p style={{ color: "red", fontSize: 12 }}>
                  Username is required
                </p>
              )}
            </div>

            <br />

            <div>
              <label htmlFor="client">Client</label>
              <input {...register("client", { required: true })} />
              {errors.Client?.type === "required" && (
                <p style={{ color: "red", fontSize: 12 }}>Client is required</p>
              )}
            </div>
            <br /> */}
            <div
              style={{ textAlign: "center", color: "#1D417A", paddingTop: 10 }}
            >
              <button
                className="formbtn"
                type="submit"
                onClick={handleSubmit(onSubmit)}
              >
                {/* <button className="formbtn"> */}
                Next
              </button>
              <button className="formbtn" onClick={close}>
                Cancel
              </button>
            </div>
          </div>
          {/* <div>{steps && <Steps success={stepTitration} />}</div> */}
        </form>
      </div>
    </div>
  );
}

export default withRouter(NewTitrations);
