import React from "react";
import "../styles/sidebar.css";
import logo from "../images/logo.png";
import { FaTools } from "react-icons/fa";

const Sidebar = (props) => {
  return (
    <>
      <div className="sidenav">
        <div className="d-flex logo">
          <img className="mx-auto" src={logo} alt="logo" />
        </div>
        <a href="#Titrations">
          <FaTools style={{ marginRight: 25 }} />
          Titrations
        </a>
      </div>
    </>
  );
};

export default Sidebar;
