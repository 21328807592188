import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Main from "./Main";
import Steps from "./Steps";

function Routes() {
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/" component={Main}>
            <Main />
          </Route>
          <Route exact path="/addstep" component={Steps}>
            {/* <Steps /> */}
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default Routes;
