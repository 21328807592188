import React from "react";
import Routes from "./components/Routes";
import { BrowserRouter as Router } from "react-router-dom";
import "./styles/style.css";
import Error from "./components/Error";

function App() {
  return (
    <Router>
      <Routes />
    </Router>
    // <Error />
    // <InputDownload />
    // <Sample />
  );
}

export default App;
