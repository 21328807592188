import React, { useState } from "react";
import Sidebar from "./Sidebar";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col, Container } from "react-bootstrap";
import Navbar from "./Navbar";
import Api from "../middleware";
import NewTitrations from "./NewTitrations";
import Error from "./Error";
import "../styles/style.css";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { Redirect, useHistory, withRouter } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const loadUrl =
  "http://ec2-100-26-208-245.compute-1.amazonaws.com:8080/list-tool-runner/rest/titration/getTitrationList";

let loadSteps = [];
// let loadData1 = [];
const response = "";

const Main = (props) => {
  const search = useLocation().search;
  const steps_id = new URLSearchParams(search).get("steps_id");
  const campaign_id = new URLSearchParams(search).get("campaign_id");
  const userDetails = new URLSearchParams(search).get("userdetails");
  for (let key in userDetails) {
    console.log(key + ":", userDetails[key]);
  }
  const abc = JSON.parse(JSON.stringify(userDetails));
  console.log(abc, "userDetails");
  console.log(steps_id, "steps_id");
  console.log(campaign_id, "campaign_id");
  let history = useHistory();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  // history.push({
  //   pathname: "/addstep",
  //   state: { detail: steps_id, campaign_id },
  // });

  const [formBox, setFormBox] = useState(false);
  const [loadData, setLoadData] = useState([]);
  const [listItem, setListItem] = useState("");
  const [detail, setDetail] = useState(false);
  const [list, setList] = useState([]);
  const [error, setError] = useState();
  const [netError, setNetError] = useState(false);

  const detailClose = () => setDetail(false);
  const handleShow = () => setDetail(true);

  const hideComponent = () => {
    setDetail(true);
    setFormBox(true);
  };

  // const handleResponse = (response) => {
  //   props.resp(response);
  // };
  const handleTitration = (data) => {
    setFormBox(false);
  };

  // const loadTitration = async () => {
  //   // console.log("Load button is working");
  //   console.log(listItem, "something");
  //   const requestObj = {
  //     method: "POST",
  //     url: loadUrl,
  //   };

  //   const response = await Api(requestObj);

  //   loadSteps = response;
  //   setLoadData(loadSteps);
  //   // console.log(loadSteps);
  // };

  const loadTitration = async () => {
    let requestObj = "";

    if (!listItem) {
      requestObj = {
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
        // url: "http://ec2-3-83-191-160.compute-1.amazonaws.com:8080/list-tool-runner/rest/titration/getTitrationList",
        url: "https://listool.digitivy.com/list-tool-runner/rest/titration/getTitrationList",
        // url: "http://ec2-3-86-231-21.compute-1.amazonaws.com:8080/list-tool-runner/rest/titration/getTitrationList",
      };
    } else {
      requestObj = {
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
        // url: "http://http://ec2-3-83-191-160.compute-1.amazonaws.com:8080/list-tool-runner/rest/titration/getTitration?",
        url: "https://listool.digitivy.com/list-tool-runner/rest/titration/getTitration?",
        // url: "http://ec2-3-86-231-21.compute-1.amazonaws.com:8080/list-tool-runner/rest/titration/getTitration",
      };
    }

    const response = await Api(requestObj);
    if (response === null) {
      setNetError(true);
    }
    loadSteps = response;
    setLoadData(loadSteps);
  };

  const selectItem = async (item) => {
    var bodyFormData = new FormData();
    bodyFormData.append("key", "Dts");
    const requestObj = {
      method: "POST",
      redirect: "follow",
      headers: { "Content-Type": "multipart/form-data" },
      // url: "http://ec2-3-83-191-160.compute-1.amazonaws.com:8080/list-tool-runner/rest/titration/getTitration?",
      url: "https://listool.digitivy.com/list-tool-runner/rest/titration/getTitration?",
      // url:"http://ec2-3-86-231-21.compute-1.amazonaws.com:8080/list-tool-runner/rest/titration/getTitration",
      params: {
        key: item,
      },
    };
    const response = await Api(requestObj);
    console.log("api is working for select", response);
    console.log("titration name", response.response.titrationName);
    if (response.status === 200) {
      setListItem(item);
      setList(response.response.steps);
      setDetail(true);
      notify();
    } else {
      setError(response.statusDescription);
    }
    history.push({
      pathname: "/addstep",
      state: {
        detail: response,
        titname: response.response.titrationName,
        stepID: steps_id,
        campaignID: campaign_id,
      },
    });
  };
  const notify = () => {
    toast.success(
      "Loading Data",
      { position: toast.POSITION.TOP_CENTER },
      { autoClose: 3000 }
    );
  };

  const onSubmit = async (data) => {
    history.push({
      pathname: "/addstep",
      state: { detail: data },
    });
    console.log(data);
    // setSteps(true);
  };

  return (
    <>
      <Row style={{ width: "100%", margin: 0 }}>
        <Col xs={2} md={2} style={{ paddingRight: 0, paddingLeft: 0 }}>
          <Sidebar />
        </Col>

        <Col
          xs={10}
          md={10}
          style={{ paddingLeft: 0, backgroundColor: "#F9FBFD" }}
        >
          <Navbar />
          <h3 style={{ paddingLeft: 30, color: "#1D417A" }}>Titrations</h3>
          <Container>
            <Col sm={8} md={8} lg={12}>
              <div style={{ textAlign: "right", padding: "20 10" }}>
                {/* <button className="titbtn">Remove</button> */}
                <button className="titbtn" onClick={hideComponent}>
                  New
                </button>
                <button className="titbtn" onClick={loadTitration}>
                  Load
                </button>
              </div>
            </Col>
            <Col sm={12} md={12} lg={12}>
              <div className="boxform">
                <div style={{ textAlign: "center" }}>
                  {netError && <Error />}
                </div>
                {loadData &&
                  loadData.response &&
                  loadData.response.length > 0 &&
                  loadData.response.map((item, index) => (
                    <p
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        borderBottom: "1px solid rgb(155, 154, 154)",
                        cursor: "pointer",
                      }}
                      onClick={() => selectItem(item)}
                      key={index}
                    >
                      {item}
                    </p>
                  ))}

                <Modal show={detail} onHide={detailClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>New Titration</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <form className="data">
                      {/* <div className="topbar">
                        <h4>New Titration</h4>
                      </div> */}
                      <br />
                      <div></div>
                      <div>
                        <div>
                          <label htmlFor="titrationName">Name</label>
                          <input
                            {...register("titrationName", { required: true })}
                          />
                          {errors.Name?.type === "required" && (
                            <p style={{ color: "red", fontSize: 12 }}>
                              Name is required
                            </p>
                          )}
                        </div>
                        <div>
                          <label htmlFor=""></label>
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            color: "#1D417A",
                            paddingTop: 10,
                          }}
                        >
                          <button
                            className="formbtn"
                            type="submit"
                            onClick={handleSubmit(onSubmit)}
                          >
                            {/* <button className="formbtn"> */}
                            Next
                          </button>
                          <button className="formbtn" onClick={detailClose}>
                            Cancel
                          </button>
                        </div>
                      </div>
                      {/* <div>{steps && <Steps success={stepTitration} />}</div> */}
                    </form>
                  </Modal.Body>
                </Modal>
                {/* {formBox && <NewTitrations success={handleTitration} />} */}
              </div>
            </Col>
          </Container>
        </Col>
      </Row>
    </>
  );
};

export default withRouter(Main);
