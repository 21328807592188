import React from "react";
import logo from "../images/Group 980.png";
function Error() {
  return (
    <div>
      <img
        src={logo}
        alt="logo"
        style={{
          marginRight: 10,
          borderRadius: "50%",
          width: 50,
          height: 50,
        }}
      />
      <p style={{ fontSize: 27, color: "#1D417A" }}>Error!</p>
      <p style={{ fontSize: 15, color: "#1D417A" }}>
        Check your connection <br /> and try again later!
      </p>
    </div>
  );
}

export default Error;
